import jwtDefaultConfig from './jwtDefaultConfig';

// const jwtService = () => {
// ** jwtConfig <= Will be used by this service
const jwtConfig = jwtDefaultConfig;

const getToken = () => {
    return localStorage.getItem(jwtConfig.storageTokenKeyName);
};

const setToken = (value) => {
    localStorage.setItem(jwtConfig.storageTokenKeyName, value);
};

const logout = () => {
    localStorage.removeItem('userData');
    localStorage.removeItem(jwtConfig.storageTokenKeyName);
};

export default { jwtConfig, getToken, setToken, logout };
