import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// axios import
import axios from 'axios';

// service object import
import service from './constant';

import jwtService from '@src/auth/jwt/jwtService';

import { store } from '@store/store';

import { handleLogout } from '@store/authentication';

import { getPermission } from '@store/permission';
import { useDispatch } from 'react-redux';

const instance = axios.create({
    baseURL: service.API_URL + '/api/v1',
});

const AxiosInterceptor = ({ children }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    // var pendingRequest = 0;
    // const loader = document.getElementById('loader');

    useEffect(() => {
        // ** Request Interceptor
        const reqInterceptors = instance.interceptors.request.use(
            (config) => {
                // pendingRequest++;
                // loader.classList.add('d-flex');
                // ** Get token from localStorage
                const accessToken = jwtService.getToken();

                // ** If token is present add it to request's Authorization Header
                if (accessToken) {
                    // ** eslint-disable-next-line no-param-reassign
                    config.headers.Authorization = JSON.parse(accessToken);
                }
                return config;
            },
            (error) => {
                // pendingRequest--;
                // if (pendingRequest <= 0) {
                //     loader.classList.remove('d-flex');
                // }
                return Promise.reject(error);
            }
        );

        // ** Add request/response interceptor
        const resInterceptors = instance.interceptors.response.use(
            (response) => {
                // pendingRequest--;
                // if (pendingRequest <= 0) {
                //     loader.classList.remove('d-flex');
                // }
                return response;
            },
            (error) => {
                // ** const { config, response: { status } } = error
                const { response } = error;

                //    pendingRequest--;
                //     if (pendingRequest <= 0) {
                //         loader.classList.remove('d-flex');
                //     }

                // ** if (status === 403)
                if (response && response.status === 403) {
                    dispatch(getPermission());
                    history.push('/home');
                    return Promise.reject(error);
                }

                if (response && response.status === 404) {
                    // history.push('/404');
                    return Promise.reject(error);
                }

                // ** if (status === 401)
                if (response && response.status === 401) {
                    dispatch(handleLogout());
                    // history.push('/login');
                    return (window.location.href = '/login');
                    return Promise.reject(error);
                }

                return Promise.reject(error);
            }
        );

        return () => {
            instance.interceptors.request.eject(reqInterceptors);
            instance.interceptors.response.eject(resInterceptors);
        };
    }, []);

    return children;
};

export default instance;
export { AxiosInterceptor };
