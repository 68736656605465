// ** Logo
// import logo from '@src/assets/images/logo/logo.png'
import ftsLogo from '@src/assets/images/logo/fts-icon.png';

const SpinnerComponent = () => {
    return (
        <div className="fallback-spinner app-loader">
            {/* <img className="fallback-logo" height="80px" src={ftsLogo} alt="logo" /> */}
            <div className="loading">
                <div className="effect-1 effects"></div>
                <div className="effect-2 effects"></div>
                <div className="effect-3 effects"></div>
            </div>
        </div>
    );
};

export default SpinnerComponent;
