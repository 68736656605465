// ** React Imports
import { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';

// ** Redux Imports
import { Provider } from 'react-redux';
import { store } from './redux/store';

// ** Intl & ThemeColors Context
import { ToastContainer } from 'react-toastify';
import { ThemeContext } from './utility/context/ThemeColors';

// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner';

// ** Ripple Button
import './@core/components/ripple-button';

// ** Fake Database
import './@fake-db';

// ** PrismJS
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import 'prismjs/components/prism-jsx.min';

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css';

// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss';

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css';
import './@core/scss/core.scss';
import './assets/scss/style.scss';
import '@styles/react/libs/tables/react-dataTable-component.scss';
import '@styles/react/libs/tables/react-devgrid.scss';

// ** Service Worker
import * as serviceWorker from './serviceWorker';

// import Loader
import { LoaderComponent } from './@core/components/loader';
import { AxiosInterceptor } from './service/axios';

// ** Lazy load app
const LazyApp = lazy(() => import('./App'));

ReactDOM.render(
    <Provider store={store}>
        <Suspense fallback={<Spinner />}>
            <ThemeContext>
                <LoaderComponent />
                <LazyApp />
                <ToastContainer newestOnTop autoClose={1500} pauseOnHover={true} />
            </ThemeContext>
        </Suspense>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
