// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// API Imports
// import { getPermissionsByToken } from "../service";
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';
import SecureLS from 'secure-ls';
import axios from '@src/service/axios';

export const secureLs = new SecureLS({ encodingType: 'aes', encryptionSecret: 'vendor-admin-panel', isCompression: false });

const initialPermission = () => {
    const data = secureLs.get('_accessible');
    return data;
};

export const getPermission = createAsyncThunk('permission/getPermission', async () => {
    showLoader(true);
    return await axios
        .get('/permission')
        .then(async (res) => {
            secureLs.set('_accessible', res.data);
            showLoader(false);
            return res.data;
        })
        .catch((err) => {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            showLoader(false);
            return [];
        });
});

export const permissionSlice = createSlice({
    name: 'permission',
    initialState: {
        permission: initialPermission(),
    },
    reducers: {
        handlePermissionChange: (state, action) => {
            state.permission = action.payload;
        },
        removePermission: (state, action) => {
            state.permission = [];
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getPermission.fulfilled, (state, action) => {
            state.permission = action.payload;
        });
    },
});

export const { handlePermissionChange, removePermission } = permissionSlice.actions;

export default permissionSlice.reducer;
