// ** Reducers Imports
import navbar from './navbar';
import layout from './layout';
import auth from './authentication';
import permission from './permission';
import user from './userProfile';
const rootReducer = {
    auth,
    navbar,
    layout,
    user,
    permission,
};

export default rootReducer;
